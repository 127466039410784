import { createContext, useEffect, useState } from "react";
import { database,  } from "./firebaseinit";
import { onValue, ref, } from "firebase/database";
export type Trip = {
    driverId: string,
    id: string, 
    departureTime: string, 
    city: string,
    address: string,
    availableSeats: number, 
    phoneNumber: string,
    driverName: string
}
type UserContextType = {  
    userId: string | undefined,
    setUserId: (userId: string | undefined) => void,
    offeringTrip: Trip | undefined,
    allTrips: Trip[]
};

export const UserContext = createContext<UserContextType>({
    userId: undefined,
    setUserId: (userId: string|undefined) => {},
    offeringTrip: undefined,
    allTrips: []
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [trips, setTrips] = useState<Trip[]>([]);
    const [offeringTrip, setOfferingTrip] = useState<Trip | undefined>(undefined);

    useEffect(() => {
        const tripsRef = ref(database, '/trips');
        onValue(tripsRef, (snapshot) => {
            const data = snapshot.val();
            setTrips(Object.entries(data || []).map(([key, value]: [any, any]) => ({ ...value, id: key })));
        }, (error) => {
            console.log(error)});
    }, [])

    useEffect(() =>{
        if(userId && userId !== ""){
            setOfferingTrip(trips.find(trip => trip.driverId === userId));
        }
    }, [userId, trips])

    return (
        <UserContext.Provider value={{ userId, setUserId, offeringTrip, allTrips:trips }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;