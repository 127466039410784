// Import the functions you need from the SDKs you need
import { initializeApp,  } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzwY0cxFEwlPvjn_v7fcSeMGLOdCFnDxE",
  authDomain: "italiancarpool.firebaseapp.com",
  projectId: "italiancarpool",
  storageBucket: "italiancarpool.appspot.com",
  messagingSenderId: "977537308505",
  appId: "1:977537308505:web:d4c402e960dfe2237b5e48",
  databaseURL: "https://italiancarpool-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export default app;