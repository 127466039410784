import React from 'react';
import { TextField } from "@mui/material"

interface Props {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CarpoolSearchBar: React.FC<Props> = ({ searchTerm, onSearchChange }) => {
  return (
    <TextField
      variant="outlined"
      label="Cerca un conducente"
      value={searchTerm}
      onChange={onSearchChange}
      style={{ marginBottom: '16px', width:'100%', maxWidth: '765px'}}
    />
  );
};

export default CarpoolSearchBar;
