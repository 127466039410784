import { Button, Card, Container, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../UserContextProvider";
import { NavLink } from "react-router-dom";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import { database } from "../firebaseinit";
import { ref, remove, update } from "firebase/database";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ModePicker: React.FC = () => {
    const { userId, offeringTrip } = useContext(UserContext);
    const navigate = useNavigate();
    const deleteTrip = () => {
        remove(ref(database, `trips/${offeringTrip?.id}`)).then(() => {
            navigate("/");
        })
    }

    const updateSeats = (delta: number) => {
        update(ref(database, `trips/${offeringTrip?.id}`), {
            availableSeats: Math.max((offeringTrip?.availableSeats || 0) + delta, 0),
        })
    }


    if (!userId || userId === "") {
        return <Navigate to="/login" />
    }
    return <Container sx={{ "display": "flex", "justifyContent": "center", "flexDirection": "column", "alignItems": "center" }}>
        {offeringTrip &&
            <Card sx={{ "display": "flex", "padding": "20px", "justifyContent": "center", "flexDirection": "column", "alignContent": "center", "maxWidth": "350px" }}>
                <Typography variant="h6" color="primary">
                    La tua offerta
                </Typography>
                <Typography variant="caption">
                    {offeringTrip.city} - {offeringTrip.address}
                </Typography>
                <Typography display="flex" alignItems="center" variant="caption">
                    <AccessTimeIcon />
                    {offeringTrip.departureTime}
                </Typography>
                <Container>
                    <IconButton
                        disabled={offeringTrip.availableSeats === 0}
                        onClick={() => { updateSeats(-1) }}><RemoveIcon></RemoveIcon></IconButton>
                    {offeringTrip.availableSeats} posti rimanenti
                    <IconButton
                        onClick={() => { updateSeats(1) }}><AddIcon /></IconButton>
                </Container>
                <Button variant="outlined" color="error" onClick={() => { deleteTrip() }}>Elimina</Button>
            </Card>
        }

        <Container sx={{ "display": "flex", justifyContent: "center", "gap": "30px", 'alignItems': 'center', padding: "20px" }}>
            <NavLink to="/driver">
                <Card variant="outlined" sx={{ padding: "10px", boxShadow: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <DirectionsCarIcon color="primary" />
                    <Typography variant="h5" color="primary">
                        Sono un conducente
                    </Typography>
                </Card>
            </NavLink>
            <NavLink to="/passenger">
                <Card variant="outlined" sx={{ padding: "10px", boxShadow: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <AirlineSeatReclineExtraIcon color="warning" />
                    <Typography variant="h5" color="warning">
                        Sono un passeggero
                    </Typography>
                </Card>
            </NavLink>
        </Container>
    </Container>
}

export default ModePicker;