import './App.css';
import Login from './components/Login';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import ModePicker from './components/ModePicker';
import SignUp from './components/SignUp';
import UserContextProvider from './UserContextProvider';
import DriverForm from './components/DriverForm';
import TripSelectorPage from './components/TripSelectorPage';
import Poster from "./poster.jpg";

function App() {
  return (
    <UserContextProvider>
      <Container className="App" sx={{ height: '100vh', width: "100vw", }}>
        <Box
          component="img"
          sx={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: 233,
            width: 400,
          }}
          alt="Logo"
          src={`${Poster}`}
        />
        <Typography variant="h6" sx={{ textAlign: "center" }}>11/06/2023</Typography>
        <Router>
          <Routes>
            <Route path="/" element={<ModePicker />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/driver" element={<DriverForm />} />
            <Route path="/passenger" element={<TripSelectorPage />} />
          </Routes>
        </Router>
      </Container>
    </UserContextProvider>
  );
}

export default App;
