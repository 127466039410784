import React, { useContext, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material';
import { auth } from '../firebaseinit';
import { UserContext} from '../UserContextProvider';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const {setUserId} = useContext(UserContext);

  const onLogin = (e: any) => {
    setError('');
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setUserId(user.uid)
        navigate('/');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          setError("Password errata");
        } else {
          setError(errorMessage);
        }
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <Container maxWidth="xs">
      {error !== '' && (<Snackbar open message={error} />)}
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={onLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Indirizzo email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            disabled={email === '' || password === ''}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Accedi
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            Non hai un account?
            <NavLink to="/signup">
              Registrati
            </NavLink>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
