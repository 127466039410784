import {  useContext, useEffect, useState } from "react"
import CarpoolList from "./CarpoolList";
import CarpoolSearchBar from "./CarpoolSearchBar";
import { Button, Container } from "@mui/material";
import { UserContext } from "../UserContextProvider";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TripSelectorPage = () => {
    const {allTrips} = useContext(UserContext);
    const [searchItem, setSearchItem] = useState("")
    const [filteredCarpoolOptions, setFilteredCarpoolOptions] = useState<any[]>([]);
    const navigate = useNavigate();
    useEffect(() =>{
        setFilteredCarpoolOptions(allTrips.filter(cp => cp["availableSeats"] > 0));
    }, [allTrips])

    const onSearchChange = (newTerm: string) => {
        setSearchItem(newTerm);
        if (newTerm === '') {
            setFilteredCarpoolOptions(allTrips)
        }
        setFilteredCarpoolOptions(allTrips.filter(cp => cp["availableSeats"] > 0 && Object.values(cp).find(k => ("" + k).toLowerCase().includes(newTerm.toLowerCase()))));
    }


    return <Container maxWidth="md">
        <Button onClick={() =>{navigate(-1)}}><ArrowBackIcon /></Button>
        <Container sx={{ padding: "20px" }}>
            <CarpoolSearchBar searchTerm={searchItem} onSearchChange={(e) => onSearchChange(e.target.value)} />
            <CarpoolList
                carpoolOptions={filteredCarpoolOptions} />
        </Container>
    </Container>
}

export default TripSelectorPage;