import React, { useContext, useState } from 'react';
import { List, Typography, Card, Button, Container, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Trip, UserContext } from '../UserContextProvider';
import { Navigate } from 'react-router-dom';


const CarpoolList: React.FC<{ carpoolOptions: Trip[] }> = ({ carpoolOptions }) => {
  const [selectedTrip, setSelectedTrip] = useState(undefined as Trip | undefined)
  const { userId } = useContext(UserContext);

  const handleListItemClick = (option: Trip) => {
    setSelectedTrip(option);
  };


  if (!userId || userId === "") {
    return <Navigate to="/login" />
  }
  return (
    <>
      <Dialog open={selectedTrip !== undefined} onClose={() => setSelectedTrip(undefined)}>
        <DialogTitle>
          {selectedTrip?.driverName}
        </DialogTitle>
        <DialogContent>
          {selectedTrip?.phoneNumber}
        </DialogContent>
      </Dialog>
      <List sx={{ display: "flex", "gap": "20px", "flexWrap": "wrap" }}>
        {carpoolOptions.map((option) => (
          <Card key={option.id} sx={{ width: "350px", "padding": "10px" }} variant="outlined" >
            <Container sx={{ "display": "flex", "flexDirection": "column", "justifyContent": "center" }}>
              <IconButton sx={{ "display": "flex", "flexDirection": "column", "gap": "3px" }} size='small' color="error" title='rechercheri en google mapi' onClick={() => {
                const w = window.open(`https://www.google.com/maps/search/?api=1&query=${option.city}+${option.address}`, '_blank');
                if (w) {
                  w.focus();
                }
              }}>
                <Typography variant="h6" display="flex" alignItems="center">
                  <LocationOnIcon />
                  {option.city}
                </Typography>
                <Typography variant="body1" sx={{ paddingLeft: 0 }}>
                  {option.address}
                </Typography>
              </IconButton>
              <Typography display="flex" alignItems="center">
                <AccessTimeIcon />
                {option.departureTime}
              </Typography>
              <br />
              <Typography>
                {option.availableSeats} posti disponibili
              </Typography>
              <br />
              <Button sx={{ "padding": "0", justifyContent: "start" }} onClick={() => handleListItemClick(option)}>
                <PhoneForwardedIcon /> Chiamare {option.driverName}
              </Button>
            </Container>
          </Card>
        ))}
      </List>
    </>
  );
};

export default CarpoolList;
