import { Box, Button, Container, Snackbar, TextField, Typography, } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import TimePicker from 'react-time-picker'
import { child, push, ref, update, remove } from "firebase/database";
import { database, } from "../firebaseinit";
import { UserContext } from "../UserContextProvider";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DriverForm: React.FC = () => {
    const { userId, offeringTrip } = useContext(UserContext)
    const [driverName, setDriverName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [availableSeats, setAvailableSeats] = useState(0);
    const [departureTime, setDepartureTime] = useState("10:00");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (offeringTrip) {
            setDriverName(offeringTrip.driverName);
            setPhoneNumber(offeringTrip.phoneNumber);
            setCity(offeringTrip.city);
            setAddress(offeringTrip.address);
            setAvailableSeats(offeringTrip.availableSeats);
            setDepartureTime(offeringTrip.departureTime);
        }
    }, [offeringTrip]);

    const onSave = () => {
        let tripKey = "";
        if(offeringTrip){
            tripKey = offeringTrip.id;
        }else{
            tripKey = push(child(ref(database), "trips")).key?.toString() || "";
        }
        update(ref(database, `trips/${tripKey}`), {
            driverId: userId,
            driverName,
            phoneNumber,
            city,
            address,
            availableSeats,
            departureTime,
        }).then(() => {
            navigate(-1);
        }).catch((e) => {
            setError(e.message)
        });
    }

    if(!userId){
        return <Navigate to="/login"></Navigate>
    }

    return (
        <>
            {(error !== "") && <Snackbar open message={error} color="error"></Snackbar>}
            <Container maxWidth="md">
                <Button onClick={() => { navigate(-1) }}><ArrowBackIcon /></Button>
                <Box  sx={{ height: "100%", "display": "flex", "gap": "20px", padding: "20px", "flexDirection": "column", }}>
                    <TextField required error={driverName.length === 0} value={driverName} onChange={(e) => setDriverName(e.target.value)} label="Il tuo nome" />
                    <TextField required error={phoneNumber.length === 0}  type="number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} label="Numero di telefono" />
                    <TextField required error={city.length === 0} value={city} onChange={(e) => setCity(e.target.value)} label="Città di partenza" />
                    <TextField required error={address.length === 0} value={address} onChange={(e) => setAddress(e.target.value)} label="Indirizzo di partenza" />
                    <TextField required type="number" error={availableSeats < 1} value={availableSeats} onChange={(e) => setAvailableSeats(parseInt(e.target.value))} label="Numero di posti disponibili" />
                    <Typography variant="body2">Orario di partenza</Typography>
                    <TimePicker disableClock clearIcon={null} value={departureTime} onChange={(newv) => { if (newv) { setDepartureTime(newv) } }} />
                    <Button variant="outlined" disabled={
                        driverName.length === 0 || 
                        phoneNumber.length === 0 ||
                        city.length === 0 ||
                        address.length === 0 ||
                        availableSeats < 1
                    } onClick={onSave}>Salva</Button>
                </Box>
            </Container>
        </>
    );
}

export default DriverForm;
