import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebaseinit";
import {
    Button,
    TextField,
    Typography,
    Box,
    Container,
    Snackbar,
} from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContextProvider";

function SignUp() {
    const {setUserId} = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                setUserId(userCredential.user.uid);
                navigate("/")
            })
            .catch((error) => {
                const errorMessage = error.code === "auth/email-already-in-use" ? "Email già in uso" : error.message;
                setError(errorMessage)
            });
    }

    return (
        <Container maxWidth="xs">
            {error !== "" && (
                <Snackbar open message={error}></Snackbar>)}
            <Box my={4}   sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
                <Typography component="h1" variant="h5">
                    Registrati
                </Typography>
                <form onSubmit={onSubmit}>
                    <TextField
                        id="email-address"
                        label="Indirizzo email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        placeholder="Indirizzo email"
                    />
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        placeholder="Password"
                    />
                    <TextField
                        id="password-confirm"
                        label="Conferma password"
                        type="password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        required
                        fullWidth
                        placeholder="Conferma password"
                    />
                    <br />
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmit}
                        disabled={password !== passwordConfirm || password.length < 6}
                    >
                        Registrati
                    </Button>
                </form>
                <Typography variant="body1">
                    Hai già un account?{" "}
                    <Link to="/login">login</Link>
                </Typography>
            </Box>
        </Container>
    );
}

export default SignUp;
